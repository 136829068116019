<template>
<svg viewBox="0 0 1504 292" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_348_483)">
    <path class="fill-zinc-950 dark:fill-white" d="M1395.63 275.291V20.4806H1430.02V275.291H1395.63Z" />
    <path
      class="fill-zinc-950 dark:fill-white"
      d="M1253.89 20.4806H1229.01L1125.11 275.291H1161.7L1184.7 217.621H1297.3L1320.11 275.291H1357.42L1253.89 20.4806ZM1196.7 187.529L1241.25 75.8389L1285.39 187.529H1196.7Z"
    />
    <path
      class="fill-zinc-950 dark:fill-white"
      d="M1101.09 274.115H1069.63L1017.8 203.224L964.999 274.115H934.634L1002.4 185.542L937.561 101.592H969.389L1018.94 166.967L1067.8 101.592H1098.16L1034.26 184.893L1101.09 274.115Z"
    />
    <path
      class="fill-zinc-950 dark:fill-white"
      d="M887.197 101.592V130.751C886.628 129.818 886.019 128.886 885.368 127.993C879.027 118.463 870.613 111.122 860.125 105.972C849.882 100.578 838.175 97.9011 825.005 97.9011C808.908 97.9011 794.397 101.957 781.47 110.027C768.544 117.854 758.301 128.602 750.74 142.309C743.423 155.774 739.765 171.104 739.765 188.218C739.765 205.333 743.423 220.298 750.74 233.762C758.301 247.227 768.544 257.974 781.47 266.044C794.397 273.872 809.03 277.806 825.371 277.806C838.297 277.806 849.882 275.251 860.125 270.1C870.613 264.706 879.027 257.244 885.368 247.713C886.019 246.781 886.628 245.848 887.197 244.874V274.115H912.806V101.592H887.197ZM872.198 235.222C861.467 247.227 847.199 253.188 829.395 253.188C817.2 253.188 806.347 250.39 796.835 244.753C787.324 239.116 779.763 231.41 774.153 221.636C768.788 211.822 766.105 200.588 766.105 187.853C766.105 175.119 768.788 163.885 774.153 154.071C779.763 144.053 787.202 136.226 796.47 130.589C805.981 124.952 816.835 122.113 829.029 122.113C841.224 122.113 851.345 124.952 860.125 130.589C869.149 135.942 876.1 143.688 880.978 153.706C886.1 163.479 888.661 174.876 888.661 187.853C888.661 207.442 883.173 223.218 872.198 235.222Z"
    />
    <path class="fill-zinc-950 dark:fill-white" d="M685.417 274.115V14.1944H711.026V274.115H685.417Z" />
    <path
      class="fill-zinc-950 dark:fill-white"
      d="M647.817 138.294C640.988 125.56 631.477 115.664 619.282 108.567C607.331 101.47 593.551 97.9011 577.942 97.9011C561.358 97.9011 546.358 101.835 532.944 109.662C519.53 117.489 508.921 128.237 501.116 141.944C493.312 155.409 489.41 170.577 489.41 187.488C489.41 204.4 493.312 220.054 501.116 233.762C509.165 247.47 520.018 258.217 533.676 266.044C547.334 273.872 562.821 277.806 580.137 277.806C593.795 277.806 606.478 275.251 618.185 270.1C630.135 264.706 640.135 257.244 648.183 247.713L631.721 230.802C625.623 238.386 618.185 244.144 609.404 248.078C600.624 251.728 590.991 253.553 580.503 253.553C567.577 253.553 556.114 250.755 546.115 245.118C536.359 239.481 528.676 231.653 523.067 221.636C519.205 214.377 516.726 206.306 515.669 197.384H656.598C657.329 193.977 657.817 191.017 658.061 188.583C658.305 185.907 658.427 183.554 658.427 181.608C658.427 165.223 654.89 150.786 647.817 138.294ZM515.75 175.362C516.807 166.846 519.124 159.262 522.701 152.611C528.067 142.796 535.383 135.212 544.651 129.859C553.919 124.465 564.772 121.748 577.211 121.748C589.649 121.748 599.283 124.343 607.575 129.494C616.112 134.361 622.575 141.458 626.965 150.786C630.582 157.842 632.696 166.034 633.306 175.362H515.75Z"
    />
    <path
      class="fill-zinc-950 dark:fill-white"
      d="M491.767 117.003L474.939 134.969C470.793 130.346 466.159 127.02 461.037 125.073C456.159 123.127 450.55 122.113 444.208 122.113C431.038 122.113 420.307 126.533 412.015 135.334C403.722 143.932 399.576 156.626 399.576 173.537V274.115H373.968V101.592H399.576V124.222C401.121 121.667 402.828 119.274 404.698 117.003C415.429 104.268 430.063 97.9011 448.598 97.9011C457.135 97.9011 464.939 99.4828 472.012 102.687C479.085 105.607 485.67 110.392 491.767 117.003Z"
    />
    <path
      class="fill-zinc-950 dark:fill-white"
      d="M146.335 292C227.154 292 292.67 226.634 292.67 146C292.67 65.3664 227.154 0 146.335 0C65.5165 0 0 65.3664 0 146C0 226.634 65.5165 292 146.335 292Z"
    />
    <path
      class="fill-white dark:fill-zinc-950"
      d="M219.503 73V146H146.335V219H73.1676V146C73.1676 105.688 105.93 73 146.335 73H219.503Z"
    />
    <path
      class="fill-white dark:fill-zinc-950"
      d="M215.072 186.961L212.023 185.379C208.324 183.514 205.357 180.553 203.447 176.822L201.861 173.781C200.479 171.063 197.674 169.36 194.626 169.36C191.577 169.36 188.772 171.063 187.39 173.781L185.805 176.822C183.935 180.513 180.968 183.473 177.228 185.379L174.179 186.961C171.456 188.34 169.749 191.138 169.749 194.18C169.749 197.222 171.456 200.02 174.179 201.399L177.228 202.981C180.927 204.846 183.895 207.807 185.805 211.538L187.39 214.579C188.772 217.297 191.577 219 194.626 219C197.674 219 200.479 217.297 201.861 214.579L203.447 211.538C205.316 207.847 208.284 204.887 212.023 202.981L215.072 201.399C217.795 200.02 219.503 197.222 219.503 194.18C219.503 191.138 217.795 188.34 215.072 186.961Z"
    />
    <path
      class="fill-zinc-950 dark:fill-white"
      d="M1504 20.4806V45.0167H1500.14V28.2267L1493.72 45.0167H1489L1482.58 28.2267V45.0167H1478.31V20.4806H1483.88L1491.16 40.7178L1498.84 20.4806H1503.96H1504Z"
    />
    <path
      class="fill-zinc-950 dark:fill-white"
      d="M1474.9 20.4806V24.3739H1467.17V45.0572H1463.31V24.3739H1455.18V20.4806H1474.85H1474.9Z"
    />
  </g>
</svg>
</template>
